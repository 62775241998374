<template>

<div class="jumbotron m-1 container-fluid">
  <h1>La paie marocaine</h1>
  <p class="lead">Dossier client</p>
  <hr class="my-4">
  <p>It uses utility classes for typography and spacing to space content out within the larger container.</p>
  <p class="lead">
    <a class="btn btn-primary btn-lg" href="#" role="button">Voir la suite</a>
  </p>
</div>
</template>

<script>
import Sidebar from '../Sidebar.vue';

export default {

    name: 'AccueilPaie',
    components: {
    Sidebar,
    }
}
</script>