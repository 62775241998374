<template>

<div class="d-flex align-items-start m-1">
  <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
    <h6><b>Structure Juridique</b></h6>
    <button class="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true" @click="this.$router.push({ path: '/maroc/paie/parametres/clsoc'})" >Sociétés</button>
    <button class="nav-link " id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false" @click="this.$router.push({ path: '/maroc/paie/parametres/cleta'})">Etablissements</button>
     <h6><b>Paramètres</b></h6>
    <button class="nav-link " id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true" @click="this.$router.push({ path: '/maroc/paie/parametres/cladresse'})" >Adresses</button>
    <button class="nav-link " id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false" @click="this.$router.push({ path: '/maroc/paie/parametres/clbanque'})">Banques</button>
    <h6 v-if="isAdmin()"><b>Administrateur</b></h6>
    <button v-if="isAdmin()" class="nav-link " id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true" @click="this.$router.push({ path: '/maroc/admin/parametres/adusers'})" >Utilisateurs</button>
    <button v-if="isAdmin()" class="nav-link " id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false" @click="this.$router.push({ path: '/maroc/admin/parametres/articles'})">Articles</button> 
      <button v-if="isAdmin()" class="nav-link " id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false" @click="this.$router.push({ path: '/maroc/admin/parametres/contacts'})">Contacts</button>   
    <button v-if="isAdmin()" class="nav-link " id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false" @click="this.$router.push({ path: '/maroc/admin/parametres/catarticles'})">Catégories d'articles</button>   
    <button v-if="isAdmin()" class="nav-link " id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true" @click="this.$router.push({ path: '/maroc/admin/parametres/macpos'})" >Codes postaux</button>
    <button v-if="isAdmin()" class="nav-link " id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false" @click="this.$router.push({ path: '/maroc/admin/parametres/mapays'})">Pays</button>  


  </div>

</div>


</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'ParametresNav',
    computed: {
        ...mapGetters(['currentUser'])
    },
    methods: { 
        isAdmin() {
            if (this.currentUser) {
              if (this.currentUser.role==="ADMIN") return true
            }
        }, 
        isClient() {
            if (this.currentUser) {
              if (this.currentUser.role==="ADMIN" || this.currentUser.role==="CLIENT") return true
            }
        },   
    }
}
</script>
<style>

.appflex {
  	display:flex;
} 

</style>