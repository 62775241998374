<template>
    <div class="row">
        <!-- Test GIT -->
        <!-- Test GIT -->     
        <div class="col-8">
            <div>
                <div class="row" v-for="( article, index ) of articles" :key="index">
                    <div class="row m-1">
                        <div class="col" v-if="index%2===0 && index < articles.length">
                            <div class="card">
                                <div class="card-body">
                                    <h6 class="card-title"><b>{{ articles[index].titre }}</b></h6>
                                    <span v-html="articles[index].extrait"></span>      
                                    <br><br>                                   
                                    <!-- <a :href="articles[index].urlarticle"  class="btn btn-primary">Lire la suite</a> -->
                                 	<router-link class="btn btn-primary" :to="articles[index].urlarticle">Lire la suite</router-link>   
                        
                                </div>
                            </div>
                        </div>
                        <div class="col" v-if="(index+1)%2===1 && index+1 < articles.length">
                            <div class="card">
                                <div class="card-body">
                                    <h6 class="card-title"><b>{{ articles[index+1].titre }}</b></h6>
                                    <span v-html="articles[index+1].extrait"></span>                             
                                    <br><br>  
                                    <!-- <a :href="articles[index+1].urlarticle"  class="btn btn-primary">Lire la suite</a> -->
                                 	<router-link class="btn btn-primary" :to="articles[index+1].urlarticle">Lire la suite</router-link>   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>
        </div>

        <div class="col">
            <div class="container m-4">
                <h4 class="text-center"><b>La paie au Maroc</b></h4>
            </div>    
            <BarreDroite/>
        </div>
    </div>
</template>
<script>
import BarreDroite from './BarreDroite.vue';
import Header from './Header.vue';
export default {
    name: 'Accueil',
    components: {
        BarreDroite,
        Header,
    },
    data() {
        return {
            articles: [],
            filters: {
                categorie: null

            },
            index: null,
        }
    },
  
    mounted() {
        this.getArticles()
    },
    methods: {
   
        async getArticles() {
            try {
                this.filters.categorie = this.articles.categorie; 
                const { data } = await this.$http.get('/articles',
                {
                    params: this.filters
                });
                this.articles = data?.items;
            } catch (e) {
                console.error(e)
            }
        },


    }

}
</script>
<style>

</style>