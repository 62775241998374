
<template>
<div v-if="!isClient()">
	<Header></Header>
	<router-view />    	
</div>

<div v-if="isClient()" class="appflex">
	<Sidebar ></Sidebar>
	<router-view />    		
</div>
	<!-- <div v-if="!isClient()" class="app">
		<PublicNav ></PublicNav>
	    <router-view />    		
	</div> -->
	<div v-if="!isClient()" class="app">
		<Footer ></Footer>   		
	</div>

</template>

<script >

import Sidebar from './components/Sidebar.vue';
import PublicNav from '../src/components/PublicNav.vue';
import Footer from './views/public/Footer.vue';
import BarreDroite from './views/public/BarreDroite.vue';
import Header from './views/public/Header.vue';
import { mapGetters } from 'vuex';

export default {

  	name: 'App',
	components: {
        PublicNav,
		Sidebar,
		Footer,
		BarreDroite,
		Header,
    }, 
    watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || 'La paie au Maroc';
            }
        },
    },
	computed: {
        ...mapGetters(['currentUser'])
    },
    methods: {
		isClient() {
			if (this.currentUser) {
				if (this.currentUser.role==="ADMIN" || this.currentUser.role==="CLIENT") return true
			}
	}
	}, 
}
</script>

<style lang ="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //text-align: center;
  color: #2c3e50;
}

</style>
